export const excessReturnColors = ["#7ab800", "#004265", "#3db7e4", "#dc5034", "#f0ac00", "#9a9b9c", "#56068c"]
export function ExcessReturnColor(seriesName) {
  var res = {
    "Steady State": excessReturnColors[0],
    "Future Value": excessReturnColors[1],
    Cash: excessReturnColors[2],
    Debt: excessReturnColors[3],
    "Market Cap": excessReturnColors[6],
    "Equity Value": excessReturnColors[4]
  }
  return res[seriesName] || excessReturnColors[5]
}
