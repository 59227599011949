import Vue from "vue"
import VueRouter from "vue-router"
import DashboardTool from "../views/FundamentalTable.vue"
import ValuationVisualization from "../views/ValuationVisualization.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/security/187/fundamental"
  },
  {
    path: "/security/:id",
    name: "home",
    redirect: "/security/:id/fundamental"
  },
  {
    path: "/security/:id/valuation",
    name: "valuation",
    component: () => import("../views/DCFCharts.vue"),
    props: true
  },
  {
    path: "/security/:id/dcf",
    name: "dcf",
    component: () => import("../views/DCF.vue"),
    props: true
  },
  {
    path: "/security/:id/fundamental",
    name: "fundamental",
    component: DashboardTool,
    props: true
  },
  {
    path: "/security/:id/visualization",
    name: "visualization",
    component: ValuationVisualization,
    props: true
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
