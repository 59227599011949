const state = {
  selectedScenario: null,
  userCanAuthor: false
}

const mutations = {
  setSelectedScenario: (state, selectedScenario) => (state.selectedScenario = selectedScenario),
  setUserCanAuthor: (state, userCanAuthor) => (state.userCanAuthor = userCanAuthor)
}

const actions = {
  async setSelectedScenario(context, selectedScenario) {
    context.commit("setSelectedScenario", selectedScenario)
  },
  async setUserCanAuthor(context, userCanAuthor) {
    context.commit("setUserCanAuthor", userCanAuthor)
  }
}

const getters = {
  selectedScenario: (state) => {
    return state.selectedScenario
  },
  userCanAuthor: (state) => {
    return state.userCanAuthor
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
