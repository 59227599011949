<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-text>
            <HistoricalDynamic :key="componentTS + bedrock_id" :getChartData="getChartData" v-if="bedrock_id" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HistoricalDynamic from "@/components/ValuationCharts/HistoricalDynamic.vue"
export default {
  props: {
    id: { type: String, requried: true }
  },
  components: {
    HistoricalDynamic
  },
  data() {
    return {
      date: this.isoDate(new Date()),
      bedrock_id: null,
      componentTS: Date.now()
    }
  },
  async mounted() {
    const quantApi = this.makeAPIClient(this.QUANT_API_HOST)
    const response = await quantApi.get(`/securities/GetSecurityMapping?security_id=${this.id}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    const sdata = await response.json()
    this.bedrock_id = sdata[0].bedrock_id
  },
  methods: {
    isoDate(date) {
      if (!date) return null

      return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
    },
    async getChartData(path) {
      const chartApi = this.makeAPIClient(this.CHART_API_HOST)
      var qs = "?"
      const { data: cdata } = await chartApi.get("/api/v1.0/" + path + "/" + this.bedrock_id + "/" + this.date + qs)
      return cdata
    }
  }
}
</script>

<style></style>
