import Vue from "vue"
import HighchartsVue from "highcharts-vue"
import Highcharts from "highcharts"
import more from "highcharts/highcharts-more"
import heatmapInit from "highcharts/modules/heatmap.js"
import VueNumerals from "vue-numerals"

import _ from "lodash"
import axios from "axios"
import * as Msal from "msal"

import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import EventBus from "./EventBus.js"

const isDev = process.env.NODE_ENV === "development"

const consoleLogLevel = ["error", "warn", "info", "debug"]
const logHandler = (level, msg) => console[consoleLogLevel[level]](msg)
const logConfig = {
  level: Msal.LogLevel[isDev ? "Verbose" : "Info"],
  piiLoggingEnabled: isDev
}

//in development, ENVs are injected automatically via .env.development file
//in docker container, ENVs are injected via window object in startup.sh
//for local container testing, we could pass --env-file=.env.development;
//when deploying to Azure App Service, this need to be set on container settings
const config = isDev ? process.env : window.appConfig
const tenantId = config.VUE_APP_AzureAd__TenantId
const clientId = config.VUE_APP_AzureAd__ClientId
const apiScope = config.VUE_APP_IMFDApi__Scope
const apiBase = config.VUE_APP_API_BASE
const GA_MEASUREMENT_ID = config.VUE_APP_GA_MEASUREMENT_ID

const msalConfig = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: location.origin + "/"
  },
  system: {
    logger: new Msal.Logger(logHandler, logConfig)
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
}

const scopes = {
  msGraph: ["https://graph.microsoft.com/User.Read"],
  serviceApi: [apiScope]
}
const msalApp = new Msal.UserAgentApplication(msalConfig)

msalApp.handleRedirectCallback((error, response) => {
  msalApp.logger.info("handleRedirectCallback", error, response)
})

const requiresInteraction = function(errorCode) {
  if (!errorCode || !errorCode.length) {
    return false
  }
  return ["consent_required", "interaction_required", "login_required"].includes(errorCode)
}

const requestAccessToken = async function(request) {
  let response = null
  try {
    response = await msalApp.acquireTokenSilent(request)
  } catch (error) {
    if (requiresInteraction(error.errorCode)) {
      response = await msalApp.acquireTokenPopup(request)
    }
  }
  if (response === null) {
    throw new Error("unable to get accessToken")
  }
  return response.accessToken
}

const interceptAxiosInstance = (instance, configOverride = {}) => {
  instance.interceptors.request.use(
    function(config) {
      return _.merge(config || {}, configOverride)
    },
    function(error) {
      if (error.request) {
        // senario like network disconected
        console.log(error.request)
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message)
      }
      console.log(error.config)
    }
  )
}

export async function makeAPIClient(baseURL) {
  const accessToken = await requestAccessToken({ scopes: scopes.serviceApi })
  const config = {
    baseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  const instance = axios.create(config)
  interceptAxiosInstance(instance)
  return instance
}

export async function makeMSGraphAPIClient(s = scopes.msGraph) {
  const accessToken = await requestAccessToken({ scopes: s })
  const config = {
    baseURL: "https://graph.microsoft.com/v1.0",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  const instance = axios.create(config)
  interceptAxiosInstance(instance)
  return instance
}

function fmtNum(num, digits = 1, percent = false, useParent = false, dashZero = false) {
  const fmtOpts = {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  }
  if (isNaN(num)) return "-"

  if (percent) return this.fmtNum(num * 100, digits, false) + "%"

  if (num < 0) {
    const result = this.fmtNum(-num, digits, false)
    return useParent ? `(${result})` : `-${result}`
  } else if (num === 0) {
    return dashZero ? "-" : "0"
  } else {
    return new Intl.NumberFormat("en-US", fmtOpts).format(num)
  }
}

Vue.config.productionTip = isDev

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Roboto, Lato, "Helvetica Neue", Helvetica, sans-serif'
    }
  }
})

more(Highcharts)
heatmapInit(Highcharts)
Vue.use(HighchartsVue)
Vue.use(VueNumerals)

function main(user) {
  const ga = document.createElement("script")
  ga.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`)
  document.body.appendChild(ga)

  window.dataLayer = window.dataLayer || []
  const gtag = function() {
    window.dataLayer.push(arguments)
  }
  gtag("js", new Date())
  gtag("config", GA_MEASUREMENT_ID, {
    page_path: "/",
    user_id: user.accountIdentifier
  })

  router.afterEach((to) => {
    gtag("config", GA_MEASUREMENT_ID, {
      page_path: to.path,
      user_id: user.accountIdentifier
    })
  })

  Vue.prototype.user = user
  Vue.prototype.EventBus = EventBus
  Vue.prototype.API_HOST = `${apiBase}/api`
  Vue.prototype.CHART_API_HOST = `${apiBase}/chart-api`
  Vue.prototype.QUANT_API_HOST = `${apiBase}/quant-api`
  Vue.prototype.OVERRIDE_HOST = `${apiBase}/overrides`
  Vue.prototype.makeAPIClient = makeAPIClient
  Vue.prototype.makeMSGraphAPIClient = makeMSGraphAPIClient
  Vue.prototype.fmtNum = fmtNum
  Vue.prototype.GA_MEASUREMENT_ID = GA_MEASUREMENT_ID

  new Vue({
    data() {
      return { secInfo: null }
    },
    router,
    store,
    vuetify,
    render: (h) => h(App)
  }).$mount("#app")
}

const user = msalApp.getAccount()

if (!user) {
  // NOT a promise as it redirects page
  // the urlContainaHash check will suspend the app from starting
  // we might supply all the consent request here
  msalApp.loginRedirect({ scopes: scopes.msGraph })
} else {
  if (msalApp.urlContainsHash(location.hash)) {
    console.log("In redirection loop, Vue app would be suspended.")
  } else {
    const sep = "*".repeat(32)
    const msg = "FINALLY START VUE APP !"
    console.log([sep, msg, sep].join("\n"))
    main(user)
  }
}
