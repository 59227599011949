import Vue from "vue"
import Vuex from "vuex"
import dcfStore from "./modules/dcfStore"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    dcfStore
  }
})
