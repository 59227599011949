import _ from "lodash"

export function timeseries(ts) {
  var rs = _.isObject(ts) ? Object.entries(ts) : ts
  var firstValueIndex = 0
  for (var i = 0; i < rs.length; i++) {
    if (rs[i][1] != null) {
      firstValueIndex = i
      break
    }
  }

  return rs.slice(firstValueIndex)
}
