<template>
  <div>
    <highcharts :options="chartOptions" :callback="addDescriptionButton"></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>Historical Dynamic</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            The starting point of the model takes the enterprise value and breaks it down into two components: a steady
            state (the value of the current earnings stream continued into perpetuity) and a future value (the value of
            the earnings that the company will create in the future):
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/historical_dynamic_1.PNG`" />
          </v-card-text>
          <div class="chart-description">
            The enterprise value is ordinarily stated as:
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/historical_dynamic_2.PNG`" />
          </v-card-text>
          <div class="chart-description">
            By rearranging the two definitions for the enterprise value, the nominal amount of future value implied in
            the current market valuations can be calculated:
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/historical_dynamic_3.PNG`" />
          </v-card-text>
          <div class="chart-description">
            We calculate the steady state by capitalising the earnings to the enterprise - net operating profit after
            tax (NOPAT) - at the weighted average cost of capital:
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/historical_dynamic_4.PNG`" />
          </v-card-text>
          <div class="chart-description">
            <p>
              Given the cyclical nature of earnings of some companies, the model allows this effect to be taken into
              consideration when calculating the steady state by normalising the earnings.
            </p>
            <p>The charts show how these five components have evolved over time.</p>
            <p>
              The model goes on to break down the nominal amount of future value implied by the stock price by stating
              explicitly what we are required to believe in terms of the amount of capital to be invested in the future
              and the returns on that capital. A central tenet of the model is that value is created only by invested
              capital at returns above the cost of that capital during a competitive advantage period;
            </p>
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/historical_dynamic_5.PNG`" />
          </v-card-text>
          <div class="chart-description">
            Users can reverse this process by putting their own invested capital and returns assumptions to get a fair
            valuation for the company.
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ExcessReturnColor } from "@/components/ValuationCharts/ExcessReturnColor.js"
import { timeseries } from "@/utils/timeseries.js"
import numeral from "numeral"
function titleCase(str) {
  str = str.toLowerCase().split(" ")
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(" ")
}
export default {
  props: ["getChartData"],
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()
    }
  },
  data: function() {
    return {
      openDialog: false,
      publicPath: process.env.BASE_URL,
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x",
          lineWidth: 0
        },
        credits: { enabled: false },
        title: {
          text: ""
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          formatter: function() {
            // The first returned item is the header, subsequent items are the
            // points
            var dt = new Date(this.x).toDateString()
            return ["<b>" + dt + "</b>"].concat(
              this.points
                ? this.points.map(function(point) {
                    return point.series.name + ": " + numeral(point.y).format("0.0a")
                  })
                : []
            )
          },
          split: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function() {
              return numeral(this.value).format("0.0a")
            }
          }
        },
        plotOptions: {
          area: {
            stacking: "normal"
          },
          series: {
            marker: {
              enabled: false
            }
          }
        },
        series: null
      }
    }
  },
  async mounted() {
    const cdata = await this.getChartData("historical_dynamic")

    this.chartOptions.series = Object.entries(cdata.plot_dict).map(([k, v]) => {
      var name = titleCase(k.replace("_", " "))
      return {
        type: ["debt", "market_cap"].includes(k) ? "line" : "area",
        name: name,
        data: timeseries(v).map(([k, d]) => [Date.parse(k), d]),
        color: ExcessReturnColor(name)
      }
    })

    this.chartOptions.title.text = cdata.title
    this.chartOptions.yAxis.title.text = cdata.currency
  }
}
</script>
<style>
.chart-description {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
