<template>
  <v-app v-cloak id="app_wrap">
    <v-app-bar app dense class="d-print-none" color="#205064">
      <a href="/" style="display:inline-flex; width: 220px">
        <img src="./assets/logo.svg" height="24" />
      </a>
      <v-spacer></v-spacer>
      <div style="flex-grow:1;">
        <v-autocomplete
          ref="autocomplete"
          v-model="select"
          :items="securityList"
          :search-input.sync="search"
          :menu-props="{ maxHeight: 500 }"
          no-filter
          solo-inverted
          dark
          single-line
          dense
          flat
          clearable
          autofocus
          auto-select-first
          hide-no-data
          hide-details
          hide-selected
          item-value="quant_security_id"
          item-text="name"
          @click="search = ''"
          @focus="search = ''"
          @change="goto"
        >
          <template v-slot:item="{ item: { ticker, name } }">
            <div class="autocomplete-item">
              <div class="autocomplete-item__name" v-text="name"></div>
              <div class="autocomplete-item__ticker" v-text="ticker"></div>
            </div>
          </template>
        </v-autocomplete>
      </div>
      <v-spacer></v-spacer>
      <div v-if="user" class="d-flex align-center">
        <span class="body-2 mr-2 white--text">Welcome, {{ user.name }}</span>
        <img :src="myProfilePhotoSrc" style="width: 32px; border-radius: 50%" />
        <v-icon>mdi-user</v-icon>
      </div>
    </v-app-bar>
    <v-main>
      <v-container fluid v-if="$root.secInfo">
        <SecurityHeader :secInfo="$root.secInfo" :varriant="headerVarriant" />
        <v-tabs
          background-color="#fff"
          height="32"
          hide-slider
          class="custom-tabs mt-2 d-print-none"
          active-class="tab-active"
        >
          <v-tab :to="`/security/${id}/fundamental`">Fundamental</v-tab>
          <v-tab :to="`/security/${id}/dcf`">DCF</v-tab>
          <v-tab :to="`/security/${id}/valuation`">Excess Returns</v-tab>
          <v-tab :to="`/security/${id}/Visualization`" v-if="false">Visualization</v-tab>
        </v-tabs>
        <div class="view-container">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </v-container>
      <div class="d-flex align-center justify-center" style="height: 80vh" v-else>
        <v-progress-circular :size="150" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import "@/scss/site.scss"
import SecurityHeader from "@/components/SecurityHeader.vue"
export default {
  name: "App",
  components: { SecurityHeader },
  data: () => ({
    myProfilePhotoSrc:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=",
    securityList: [],
    search: null,
    select: ""
  }),

  async mounted() {
    try {
      const instance = await this.makeMSGraphAPIClient()
      const img = await instance.get("/me/photos/48x48/$value", {
        responseType: "blob" // important
      })
      this.myProfilePhotoSrc = window.URL.createObjectURL(new Blob([img.data]))
    } catch {
      console.log("failed to get user profile image")
    }
    this.$watch(
      "$route.params.id",
      async (newV) => {
        if (newV) {
          this.$root.secInfo = await this.loadSecInfo(newV)
        }
      },
      { immediate: true }
    )
  },

  computed: {
    id() {
      return this.$route.params.id
    },
    headerVarriant() {
      return this.$route.name
    }
  },

  methods: {
    async loadSecInfo(id) {
      const apiClient = await this.makeAPIClient(this.QUANT_API_HOST)
      const { data: secInfo } = await apiClient.get(`valuation/GetDcfSecurityInfo?security_id=${id}`)
      return secInfo
    },
    async goto(id) {
      if (id === undefined) return
      this.$router.push({
        name: this.$route.name,
        params: { id: String(id) }
      })
    },
    debounceSearch: _.debounce(async function(value, old) {
      const apiClient = await this.makeAPIClient(this.QUANT_API_HOST)
      if (!value || value === old) return
      const { data } = await apiClient.get(`/issuer/search?q=${value}`)
      this.securityList = data
    }, 300)
  },

  watch: {
    async search(value, old) {
      this.debounceSearch(value, old)
    }
  }
}
</script>

<style lang="scss">
html {
  overflow-x: auto !important;
  overflow-y: scroll !important;
}

.view-container {
  background-color: #fafafa;
}

@media print {
  #app {
    background: #fff !important;
  }
  .v-toolbar {
    display: none;
  }
  .container {
    padding: 0 !important;
  }
  .v-content {
    padding: 0 !important;
  }
  .tableizer-table td {
    font-size: 20px !important;
  }
}

@media only screen {
  #app_wrap {
    min-width: 1242px;
  }
}

@media only screen and (max-width: 1920px) {
  .container {
    max-width: 100%;
  }
}

.autocomplete-item {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.autocomplete-item__ticker {
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.autocomplete-item__name {
  color: #666;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-tabs {
  /* border-bottom: 1px solid #014165; */
}

.custom-tabs .tab-active.v-tab--active {
  background-color: #014165 !important;
  color: #fff !important;
}

.custom-tabs .v-tab {
  background-color: #fff;
  color: #014165 !important;
  /* border: 1px solid #014165; */
  /* border-bottom: none; */

  &:not(:first-child) {
    margin-left: -1px;
  }
}
</style>
