<template>
  <div v-if="secInfo" style="position:relative">
    <div class="data-update-desc">
      Data Source:
      <a target="_blank" href="https://www.refinitiv.com/en/products/qa-direct-quantitative-analysis">
        Refinitive QA Direct
      </a>
      . Last updated at {{ secInfo.lastUpdated }}
    </div>
    <table class="fundamental-table">
      <tbody>
        <tr class="segment">
          <td colspan="1">Returns Profile</td>
          <td>
            {{ tableHeaders[0] }}
            <br />
            FY-3
          </td>
          <td>
            {{ tableHeaders[1] }}
            <br />
            FY-2
          </td>
          <td>
            {{ tableHeaders[2] }}
            <br />
            FY-1
          </td>
          <td>
            {{ tableHeaders[3] }}
            <br />
            FYA
          </td>
          <td>
            {{ tableHeaders[4] }}
            <br />
            FY1
          </td>
          <td>
            {{ tableHeaders[5] }}
            <br />
            FY2
          </td>
          <td>
            {{ tableHeaders[6] }}
            <br />
            FY3
          </td>
          <td style="text-decoration: overline">
            <br />
            5Y
          </td>
          <td style="text-decoration: overline">
            <br />
            10Y
          </td>
        </tr>
        <tr>
          <td>
            {{ secInfo.SECTOR === "Financials" ? "ROE (%)" : "CFROIC (%)" }}
          </td>
          <td v-for="(v, index) in tableRows.CFROIC || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.CFROIC_MEDIAN || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td>
            {{ secInfo.SECTOR === "Financials" ? "ROE" : "CFROIC" }}
            Volatility (%)
          </td>
          <td v-for="(v, index) in tableRows.CFROIC_VOLATILITY || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td
            v-for="(v, index) in tableRows.PEER_CFROIC_VOLATILITY || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td>Operating Margin (%)</td>
          <td v-for="(v, index) in tableRows.CFROIC_MARGIN || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.CFROIC_MARGIN_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Asset Turnover</td>
          <td
            v-for="(v, index) in tableRows.CFROIC_ASSET_TURN || Array(9)"
            :key="index"
            v-html="numFmt(v, 1, 'x')"
          ></td>
        </tr>
        <tr>
          <td>Reinvestment Rate (%)</td>
          <td v-for="(v, index) in tableRows.CFROIC_REINVEST_RATE || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Return on Incremental Investment (%)</td>
          <td v-for="(v, index) in tableRows.CFROIC_RET_INC_INV || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
      </tbody>
      <tbody>
        <tr class="segment">
          <td colspan="1">Industry Group Metrics</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="warn">
            Industry Returns Spreads (90th Ptile - 10th Ptile)
          </td>
          <td
            v-for="(v, index) in tableRows.CFROIC_INDUSTRY_RETURN_SPREAD || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td class="warn">
            Global Market Returns Spreads (90th Ptile - 10th Ptile)
          </td>
          <td
            v-for="(v, index) in tableRows.CFROIC_GLOBAL_RETURN_SPREAD || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td class="warn">Industry Stickiness</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.CFROIC_INDUSTRY_STICKINESS || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
      </tbody>
      <tbody>
        <tr class="segment">
          <td colspan="1">
            Growth
            <span class="currency-amount">({{ secInfo.CURRENCY }} millions where applicable)</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Sales</td>
          <td v-for="(v, index) in tableRows.SAL || Array(9)" :key="index" v-html="numFmt(v, 0)"></td>
        </tr>
        <tr>
          <td class="indent-1">Growth (%)</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.SAL_GROWTH || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td class="indent-1">&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.SAL_GROWTH_MEDIAN || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td>EPS ({{ secInfo.CURRENCY }})</td>
          <td v-for="(v, index) in tableRows.EPS || Array(9)" :key="index" v-html="numFmt(v, 2)"></td>
        </tr>
        <tr>
          <td class="indent-1">Growth (%)</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.EPS_GROWTH || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td class="indent-1">&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.EPS_GROWTH_MEDIAN || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td>Change in Share Count YoY (%)</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.CHG_SHARECOUNT || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr>
          <td>Profits to Cash Flow (%)</td>
          <td v-for="(v, index) in tableRows.PROFITS_TO_CF || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.PROFITS_TO_CF_MEDIAN || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
      </tbody>
      <tbody>
        <tr class="segment">
          <td colspan="1">
            Capital Allocation
            <span class="currency-amount">({{ secInfo.CURRENCY }} millions where applicable)</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>CAPEX</td>
          <td v-for="(v, index) in tableRows.CA_CAPEX || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>R&amp;D</td>
          <td v-for="(v, index) in tableRows.CA_RD || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Capex + R&amp;D (% of Cash Flow from Operations)</td>
          <td v-for="(v, index) in tableRows.CPRD_RATIO || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.CPRD_RATIO_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>M&amp;A</td>
          <td v-for="(v, index) in tableRows.CA_MA || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>M&amp;A (% of Cash Flow from Operations)</td>
          <td v-for="(v, index) in tableRows.MA_RATIO || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.MA_RATIO_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Dividends</td>
          <td v-for="(v, index) in tableRows.CA_DIV || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Equity Buybacks</td>
          <td v-for="(v, index) in tableRows.CA_EQ_BUYBACK || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Equity Issuance</td>
          <td v-for="(v, index) in tableRows.CA_EQ_ISSUANCE || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Debt Buyback</td>
          <td v-for="(v, index) in tableRows.CA_DEBT_BUYBACK || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>
            Dividends + Equity Buybacks - Equity Issuance + Debt Buyback
          </td>
          <td v-for="(v, index) in tableRows.DIV_EQBUYBACK_RMN || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>
            Capital Allocation to Shareholders (% of Cash Flow from Operations)
          </td>
          <td v-for="(v, index) in tableRows.SHLDRS_EQ_RATIO || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td
            v-for="(v, index) in tableRows.SHLDRS_EQ_RATIO_MEDIAN || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
      </tbody>
      <tbody>
        <tr class="segment">
          <td colspan="1">Earnings Revision</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>EPS Year 1</td>
          <td v-for="(v, index) in tableRows.RV_EPS_FY1 || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>EPS Year 2</td>
          <td v-for="(v, index) in tableRows.RV_EPS_FY2 || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Rev Year 1</td>
          <td v-for="(v, index) in tableRows.RV_SAL_FY1 || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Rev Year 2</td>
          <td v-for="(v, index) in tableRows.RV_SAL_FY2 || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
      </tbody>
      <tbody>
        <tr class="segment">
          <td colspan="1">Valuation</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>P/E</td>
          <td v-for="(v, index) in tableRows.V_PE || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.V_PE_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>EV/EBIT</td>
          <td v-for="(v, index) in tableRows.V_EEB || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.V_EEB_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>P/FC</td>
          <td v-for="(v, index) in tableRows.V_PFC || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.V_PFC_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>P/B</td>
          <td v-for="(v, index) in tableRows.V_PB || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.V_PB_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>Dividend Yield (%)</td>
          <td v-for="(v, index) in tableRows.V_DIV_YIELD || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.V_DIV_YIELD_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>EV/IC</td>
          <td v-for="(v, index) in tableRows.V_EV_IC || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.V_EV_IC_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1, 'x')"></td>
        </tr>
        <tr>
          <td>Free Cash Flow Yield (%)</td>
          <td v-for="(v, index) in tableRows.V_FCF_YIELD || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>&#9492;&#9472;{{ secInfo.MKT_SPEC }}</td>
          <td v-for="(v, index) in tableRows.V_FCF_YIELD_MEDIAN || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
      </tbody>
      <tbody>
        <tr class="segment">
          <td colspan="1">
            Balance Sheet Ratios
            <span class="currency-amount">({{ secInfo.CURRENCY }} millions where applicable)</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Net Debt/EV (%)</td>
          <td v-for="(v, index) in tableRows.NET_DEBT_TO_EV || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Net Debt/EBITDA</td>
          <td
            v-for="(v, index) in tableRows.NET_DEBT_TO_EBITDA || Array(9)"
            :key="index"
            v-html="numFmt(v, 1, 'x')"
          ></td>
        </tr>
        <tr>
          <td>Cash</td>
          <td v-for="(v, index) in tableRows.WS_CASH || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Gross Debt</td>
          <td v-for="(v, index) in tableRows.WS_DEBT_TOTAL || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
      </tbody>
      <tbody>
        <tr class="segment">
          <td colspan="1">CAGRS</td>
          <td colspan="9"></td>
        </tr>
        <tr>
          <td>Dividend Growth (%)</td>
          <td v-for="(v, index) in tableRows.CAGRS_DIV_YLD || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>Book Value Growth (%)</td>
          <td v-for="(v, index) in tableRows.CAGRS_BV || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
        <tr>
          <td>EPS Growth (%)</td>
          <td
            class="pct"
            v-for="(v, index) in tableRows.EPS_GROWTH || Array(9)"
            :key="index"
            v-html="numFmt(v, 1)"
          ></td>
        </tr>
        <tr style="line-height: 20px;">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="font-weight: bold">YTD</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Total Shareholder Return Growth (%)</td>
          <td v-for="(v, index) in tableRows.CAGRS_TSR || Array(9)" :key="index" v-html="numFmt(v, 1)"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, required: true }
  },
  data() {
    return { secInfo: null, tableRows: {}, tableHeaders: [] }
  },
  async mounted() {
    this.loading = true
    const apiClient = await this.makeAPIClient(this.API_HOST)
    if (!this.secInfo) {
      const { data } = await apiClient.get(`/security-info?id=${this.id}`)
      this.secInfo = data
    }

    const { data: dataRet } = await apiClient.get(`/data?id=${this.id}`)
    this.tableRows = dataRet.tableRows
    this.tableHeaders = dataRet.tableHeaders
    this.loading = false
  },
  methods: {
    getDate(v) {
      let date = null
      if (typeof v === "string") {
        const dateNum = Date.parse(v)
        if (Number.isNaN(dateNum)) return null
        date = new Date(dateNum)
      } else if (typeof v === "number") {
        date = new Date(v)
      } else if (!(v instanceof Date)) {
        return null
      }
      return date
    },
    //TODO
    numFmt(value, fractionDigits = 1, suffix = "") {
      if (value === null || value === undefined || typeof value !== "number" || isNaN(value))
        return '<span style="color:#ccc">NA</span>'
      const numFormater = Intl.NumberFormat("en-US", {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
      })
      if (value >= 0) {
        return `${numFormater.format(value)}${suffix}`
      }
      return `(${numFormater.format(-value)})${suffix}`
    }
  }
}
</script>

<style lang="scss">
.fundamental-table {
  font-size: 16px;
  width: 1350px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  tr td {
    border-bottom: 1px solid #ccc;
    font-weight: 400;
    padding: 4px 8px;
    text-align: right;

    &:first-child {
      font-family: "Roboto Slab", serif;
      width: 420px;
      text-align: left;
    }
  }

  tr:nth-child(2n) {
    background: #eee;
  }

  tr.segment td {
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    font-weight: bold;
    height: 45px;
    line-height: 1;
    background: #fafafa;
    white-space: nowrap;
    overflow: visible;
    color: #333;
    vertical-align: bottom;

    &:not(:first-child) {
      font-size: 16px;
    }
  }

  tr:not(.segment) td {
    &:nth-child(6),
    &:nth-child(9) {
      border-left: 1px solid #ccc;
    }
  }

  tbody:first-child tr.segment td,
  tbody:not(:first-child) tr.segment td:first-child {
    position: sticky;
    top: 48px;
  }

  tr td:first-child.indent-1 {
    padding-left: 24px;
  }
}

.currency-amount {
  font-size: 14px !important;
  color: #333;
}
</style>
