<template>
  <header class="table-header">
    <div style="font-size: 14px; font-weight: 500">
      <span>{{ secInfo.SECTOR }}&nbsp;&gt;&nbsp;</span>
      <span>{{ secInfo.INDGRP }}&nbsp;&gt;&nbsp;</span>
      <span>{{ secInfo.IND }}&nbsp;&gt;&nbsp;</span>
      <span>{{ secInfo.name }}</span>
      <v-badge
        v-if="secInfo.BBID !== secInfo.BBID_RAW"
        top
        offset-x="100%"
        :content="`Parent security shown for ${secInfo.BBID_RAW}`"
        color="deep-purple accent-4"
      ></v-badge>
    </div>
    <div class="header-data-items">
      <div>
        <div style="font-size: 24px; font-weight: 400; position: relative; top:6px ">
          {{ secInfo.BBID }}
        </div>
        <a style="font-size: 11px; text-decoration:none" :href="`bbg://securities/${secInfo.BBID} Equity/FA`">
          View in Bloomberg
        </a>
      </div>
      <div class="info-item">
        <div class="item-text">{{ fmtNum(secInfo.FL_MKT_CAP, 0) }}</div>
        <div class="item-label">Mkt Cap Float Adj($M)</div>
      </div>
      <div class="info-item" v-show="secInfo.FYA && varriant === 'fundamental'">
        <div class="item-text">{{ secInfo.ESG_SCORE }}</div>
        <div class="item-label">ESG Rating</div>
      </div>
      <div class="info-item" v-show="secInfo.FYA && varriant === 'fundamental'">
        <div class="item-text">{{ secInfo.ANALYST_RATING }}</div>
        <div class="item-label">Analyst Rating</div>
      </div>
      <div class="info-item" v-show="secInfo.FYA && varriant === 'fundamental'">
        <div class="item-text">{{ secInfo.NUM_EST }}</div>
        <div class="item-label"># of Estimates</div>
      </div>
      <div class="info-item" v-show="secInfo.FYA">
        <div class="item-text">{{ secInfo.FYA }}</div>
        <div class="item-label">Fiscal Year End</div>
      </div>
      <div class="info-item" v-show="secInfo.day && varriant === 'fundamental'">
        <div class="item-text">{{ secInfo.day }}</div>
        <div class="item-label">Estimates as of</div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    secInfo: {
      type: Object,
      required: true
    },
    varriant: {
      type: String,
      default: "dcf"
    }
  }
}
</script>

<style>
.header-data-items {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-start;
}

.header-data-items .info-item {
  margin-left: 48px;
}

header.table-header {
  font-weight: 400;
  font-size: 16px;
  color: #1e1e1e;
  line-height: 1.2;
}

.info-item .item-text {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.info-item .item-label {
  text-transform: uppercase;
  font-size: 10px;
  color: #666;
}

.info-item span:nth-child(2) {
  margin-left: 10px;
}
</style>
